@import "./variables";
@import "./bootstrap/bootstrap.scss";
@import "./ck-editor";

//Components
@import "./template-editor";
@import "./store-landing-page";
@import "./editor-store-landing-page";
@import "./template-previw";
@import "./home-page";
@import "./layout";
@import "./banjos-input";
@import "./general";
@import "./fonts";

body {
  margin: 0;
  font-family: $base-font;
}

//custom scrollbar
::-webkit-scrollbar {
  width: 7px;
  height: 5px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: $gray-90; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: $gray-60; /* color of the scroll thumb */
  border-radius: 20px;
}
// common items here - which are generally used
.secondary-text {
  color: $secondary-font-color !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
