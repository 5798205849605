.editor-store-landing-wrapper {
  //MainBannerBlock
  //   .main-banner-block-wrapper {
  //     //for mobile
  //     .image-wrapper {
  //       position: relative;
  //       width: 100%;
  //       height: 288px;
  //       background-repeat: no-repeat;
  //       background-size: cover;
  //       background-position: center;
  //       .content-container {
  //         width: 100%;
  //         height: 100%;
  //         display: flex;
  //         flex-direction: column;
  //         align-items: center;
  //         justify-content: center;
  //         gap: 10px;
  //         .store-name {
  //           font-family: $fancy-font;
  //           font-style: normal;
  //           font-weight: 400;
  //           font-size: 48px;
  //           line-height: 49px;
  //           text-align: center;
  //           color: #fffceb;
  //           margin: 0;
  //         }
  //         .open-time-container {
  //           display: flex;
  //           flex-direction: column;
  //           gap: 10px;
  //           .open-time-title {
  //             display: none;
  //             font-family: $base-font;
  //             font-weight: 400;
  //             font-size: 24px;
  //             line-height: 32px;
  //             text-align: center;
  //           }
  //           .open-times-wrapper {
  //             display: flex;
  //             flex-direction: column;
  //             align-items: center;
  //             .open-time {
  //               font-family: $base-font;
  //               font-weight: 400;
  //               font-size: 13px;
  //               line-height: 20px;
  //             }
  //           }
  //         }
  //         .buttons-wrapper {
  //           display: flex;
  //           flex-direction: row;
  //           gap: 32px;
  //           .call-btn,
  //           .direction-btn {
  //             width: 40px;
  //             height: 40px;
  //             display: flex;
  //             flex-direction: row;
  //             align-items: center;
  //             justify-content: center;
  //             border-radius: 80px;
  //             cursor: pointer;
  //             &:hover {
  //               box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16),
  //                 0px 2px 4px rgba(0, 0, 0, 0.25);
  //             }
  //             img {
  //               height: 24px;
  //               width: 24px;
  //             }
  //             span {
  //               display: none;
  //               font-family: $base-font;
  //               font-weight: 400;
  //               font-size: 18px;
  //               line-height: 16px;
  //               text-align: center;
  //               text-transform: uppercase;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }

  .text-selected {
    outline: 2px solid #00cc52;
    background: rgba(109, 180, 246, 0.5);
  }

  //ImageBannerBlock
  .image-banner-block-wrapper {
    //mobile resposive
    .image-banner-block-container {
      background: rgba($color: #000000, $alpha: 0.65);
      .banner-image {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        box-sizing: border-box;
        padding: 0;
        border: none;
        margin: auto;
        display: block;
        width: 0;
        height: 0;
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
        mix-blend-mode: overlay;
      }

      .banner-fancy-text {
        width: max-content;
        background: none;
        text-align: center;
        margin: auto;
      }
    }
  }

  //FavouriteCategoryBlock
  .favourite-category-block-wrapper {
    .favourite-category-block-container {
      .favourite-category-block-head-wrap {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 40px;
        width: 100%;
        .favourite-category-add-item {
          position: absolute;
          right: 40px;
          top: 0;
          bottom: 0;
          width: 121px;
          height: 46px;
          border-radius: 16px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 12px;
          padding: 8px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 16px;
          text-align: center;
          color: #5e7360;
          button {
            img {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
      .favourite-category-block-items {
        .favourite-category-block-item {
          .secound-col {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 24px;
            .delete-btn {
              width: 53.02px;
              height: 54.75px;
              border: 1px solid $gray-90;
              border-radius: 16px;
              img {
                width: 29.02px;
                height: 30.75px;
                filter: invert(46%) sepia(20%) saturate(358%) hue-rotate(75deg)
                  brightness(87%) contrast(88%);
              }
              &:hover {
                border: 1px solid $gray-80;
              }
            }
          }
        }
      }
    }
  }
  //TextBlock
  .text-block-wrapper {
    .text-block-container {
      .text-items-container {
        position: relative;
        .ck {
          padding: 0;
          background: transparent;
          color: #fff;
          border: none;
          box-shadow: none;
          h1,
          h2,
          p,
          ul {
            margin-block-start: 0.2em;
            margin-block-end: 0.2em;
          }
        }
      }
    }
  }

  //WeAreAvailableBlock
  .we-are-available-block-wrapper {
    .we-are-available-block-container {
      .we-are-available-box {
        .we-are-available-items {
          .we-are-available-item {
            width: max-content;
            min-width: 191px;
            padding: 16px;
            .delete-btn {
              margin-left: 8px;
              width: 22.02px;
              height: 23.33px;
              filter: invert(46%) sepia(20%) saturate(358%) hue-rotate(75deg)
                brightness(87%) contrast(88%);
              &:hover {
                filter: none;
              }
            }
          }
        }
        .we-are-available-add-item {
          width: 60px;
          height: 60px;
          border-radius: 400px;
          border: 1px solid $gray-90;
          img {
            width: 20px;
            height: 20px;
          }
          &:hover {
            border: 1px solid $gray-80;
          }
        }
      }
    }
  }

  //ProudlySupportBlock
  .proudly-support-block-wrapper {
    .proudly-support-block-container {
      .proudly-support-block-flexbox {
        .proudly-support-block-items {
          .proudly-support-block-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 16px;

            button {
              width: 37.02px;
              height: 38.75px;
              border: 1px solid $gray-90;
              border-radius: 8px;
              img {
                width: 29.02px;
                height: 30.75px;
                filter: invert(46%) sepia(20%) saturate(358%) hue-rotate(75deg)
                  brightness(87%) contrast(88%);
              }
              &:hover {
                border: 1px solid $gray-80;
              }
            }
          }
          .proudly-support-block-add-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 16px;

            button {
              width: 60px;
              height: 60px;
              border-radius: 400px;
              border: 1px solid $gray-90;
              img {
                width: 20px;
                height: 20px;
              }
              &:hover {
                border: 1px solid $gray-80;
              }
            }
          }
        }
      }
    }
  }
}
