//BanjosNavbar//
.banjos-navbar-wrapper {
  top: 0;
  position: sticky;
  z-index: 99;
  background: #fff;
  width: 100%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  .Navbar-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 35px;
    padding: 18px 32px;
    .left-section {
      height: 36px;
      .text-logo {
        height: 36px;
        width: auto;
      }
    }
    .center-section {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
    .right-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;
      .preview-btn {
        width: 30px;
        height: 30px;
        cursor: pointer;
        border-radius: 8px;
        &:hover {
          background-color: $gray-95;
        }
      }
      .user-sign-in {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 16px;
        gap: 4px;
        .signin-text {
          display: inline;
        }
      }
    }
  }

  .responsiveDropdown {
    position: relative;
    z-index: 12;
    button {
      width: 70px;
      height: 36px;
      border: 1px solid rgba(94, 115, 96, 0.15);
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #fff;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      &:hover {
        border: 1px solid rgba(94, 115, 96, 0.45);
      }
    }
    ul {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      right: 0;
      top: 100%;
      width: 250px;
      background: #ffffff;
      padding: 6px 0px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      -webkit-border-radius: 8px;
      list-style: none;
      margin: 0;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      li {
        padding: 15px 12px;
        font-weight: 400;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 12px;
        font-family: "Roboto";
        cursor: pointer;
        border-bottom: 1px solid rgba(94, 115, 96, 0.15);
        // transition: all 0.3s ease;
        // -webkit-transition: all 0.3s ease;
        &:last-child {
          border-bottom: none;
        }
        &.selected {
          background: url("../images/check-icon.svg") no-repeat 95% center;
        }
      }
      &.open {
        top: calc(100% + 10px);
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
//Navbar
.Navbar-wrapper {
  top: 0;
  position: sticky;
  z-index: 98;
  background: #fff;
  width: 100%;
  //mobile responsive
  .Navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    .logo-section {
      height: 24px;
      .text-logo {
        height: 24px;
        width: auto;
      }
    }
    .user-section {
      .user-sign-in {
        display: flex;
        flex-direction: row;
        .user-logo {
          width: 24px;
          height: 24px;
        }
        .signin-text {
          display: none;
        }
      }
    }
  }
  //desktop resposive
  @media (min-width: 768px) {
    .Navbar-content {
      display: flex;
      justify-content: center;
      .Navbar-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 16px;
        box-sizing: border-box;
        width: 100%;
        .logo-section {
          height: 32px;
          .text-logo {
            height: 32px;
            width: auto;
          }
        }
        .user-section {
          .user-sign-in {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;
            gap: 4px;
            .signin-text {
              display: inline;
            }
          }
        }
      }
    }
  }
}

.Footer-wrapper {
  .Footer-container {
    //small footeer
    .footer-small-wrap {
      .footer-small {
        display: flex;
        flex-direction: column;
        padding: 12px 16px;
        gap: 20px;
        background-color: #f2f2f2;
        &.mobile{
          flex-direction: column;
        }
        .social {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 25px;
          height: 30px;
          img {
            width: 30px;
            height: 30px;
          }
        }
        .copyright {
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            font-size: 12px;
            line-height: 16px;
            margin: 0;
          }
        }
      }
    }

    //big footer
    .footer-big-wrap {
      .footer-big {
        display: flex;
        flex-wrap: wrap;
        background-color: #5e7360;
        padding: 50px 16px;
        box-sizing: border-box;
        //mobile
        &.mobile {
          flex-direction: column;
          .logo-div {
            width: 100%;
            .logo {
              margin: 0 auto 48px;
            }
            .Download-div {
              align-items: center;
              .download {
                margin-bottom: 10px;
              }
              .app-store-logos {
                justify-content: center;
                gap: 0px;
                .icon {
                  max-width: 140px;
                  height: auto;
                  margin: 0 5px;
                }
              }
            }
          }
          .arrangement {
            width: 100%;
            margin-top: 27px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: flex-start;
            justify-content: space-between;
            gap: 43px;
            row-gap: 27px;
          }
        }
        .logo-div {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .Download-div {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            .download {
              font-size: 16px;
              line-height: 19px;

              color: #fffceb;
            }
            .app-store-logos {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
              max-width: 440px;
              .icon {
                width: 159.5px;
                height: 50px;
              }
            }
          }

          .logo {
            width: 180px;
            height: 56px;
          }
        }
        .arrangement {
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 66.66%;
          h4 {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #fffceb;
          }
          p {
            display: flex;
            flex-direction: column;
            gap: 8px;
            a {
              font-size: 13px;
              line-height: 17px;
              color: #fffceb;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  //desktop resposive
  @media (min-width: 768px) {
    .Footer-container {
      //small footeer
      .footer-small-wrap {
        display: flex;
        justify-content: center;
        background-color: #f2f2f2;
        .footer-small {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 12px 16px;
          gap: 20px;
          box-sizing: border-box;
          width: 100%;
        }
      }
      //big footer
      .footer-big-wrap {
        display: flex;
        justify-content: center;
        background-color: #5e7360;
        .footer-big {
          display: flex;
          flex-wrap: wrap;
          background-color: #5e7360;
          padding: 48px 16px;
          box-sizing: border-box;
          width: 100%;
          .logo-div {
            width: 33.33%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            .Download-div {
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              width: 100%;
              .download {
                font-size: 16px;
                line-height: 19px;

                color: #fffceb;
              }
              .app-store-logos {
                display: flex;
                flex-direction: row;
                justify-content: unset;
                width: 100%;
                max-width: unset;
                gap: 25px;
                .icon {
                  width: 170px;
                  height: 50.38px;
                }
              }
              .app-store-logos-edit {
                display: flex;
                flex-direction: row;
                justify-content: unset;
                width: 100%;
                max-width: unset;
                gap: 25px;
                .icon {
                  width: 100px;
                  height: 50.38px;
                }
              }
            }
          }
          .edit {
            width: 24%;
          }

          .edit-arrangement {
            width: 100%;
            overflow-x: auto;

            display: flex;
            flex-direction: row;

            h4 {
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              color: #fffceb;
            }
            p {
              display: flex;
              flex-direction: column;
              gap: 8px;
              a {
                font-size: 13px;
                line-height: 17px;
                color: #fffceb;
                text-decoration: none;
              }
            }
            .column-container {
              margin: 8px;
              border-radius: 8px;
              min-width: 180px;
              max-width: 220px;
              background-color: #0000005c;
              display: flex;
              flex-direction: column;
              padding: 10px;
              &.hide {
                background: #606761;
                opacity: 0.9;
              }
            }
            .column-header {
              display: flex;
              flex-direction: row;
              align-items: baseline;
              min-width: 190px;
            }
            .colum-content {
              padding-left: 7px;
              font-size: 13px;
              max-width: 164px;
              min-width: 100px;
              h4 {
                &.hide {
                  color: #999999;
                }
              }
            }
            .item-list {
              display: flex;
              flex-direction: column;
              transition: background-color 0.2s ease;
              flex-grow: 1;
              min-height: 100px;
              .item-container {
                border-radius: 4px;
                padding: 6px;
                margin-bottom: 8px;
                display: flex;
                align-items: baseline;
                min-height: 15px;
                &.dragging {
                  background-color: #5e7360;
                  border: 1px solid #fffceb;
                }
                .content {
                  color: #fffceb;
                  padding-left: 7px;
                  font-size: 13px;
                  min-width: 70px;
                  div {
                    &.hide {
                      color: #999999;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
