.template-editor-wrapper {
  background-color: $gray-97;
  max-width: 100vw;
  height: calc(100vh - 72px);
  ////
  .row {
    //template-editor-canvas
    height: calc(100vh - 72px);
    padding-top: 8px;
    .template-editor-canvas {
      flex: 1 0;
      padding: 24px 30px 0 30px;
      overflow: hidden;
      height: 100%;
      [contenteditable]:focus-visible {
        outline: 2px solid #00cc52;
        background: rgba(109, 180, 246, 0.5);
      }
      .element-selected {
        outline: 2px solid #00cc52;
        outline-offset: -2px;
        &.category-items {
          outline-offset: 5px;
        }
        &.favourite-category-block-item,
        &.we-are-available-item,
        &.proudly-support-logo {
          outline-offset: 8px;
        }
      }
      .editor-canvas-wrapper {
        // margin: 50px 25px 0 35px;
        height: 100%;
        background-color: #fff;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        margin: 0 auto;
        font-size: large;
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-track {
          background: $gray-95; /* color of the tracking area */
          border-width: 0.5px;
          border-style: solid;
          border-color: #cccccc;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $gray-80; /* color of the scroll thumb */
          border-radius: 30px;
          border: 2px solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
        }
        &.mobile {
          width: 400px;
        }
      }
    }
    .template-editor-tools {
      width: 290px;
      background-color: #fff;
      overflow: hidden;
      height: 100%;
      &.edit {
        overflow: auto;
      }
      .editor-toolbar-wrapper {
        position: relative;
        height: 100%;
        .editor-toolbar-header-wrapper {
          display: flex;
          flex-direction: row;
          gap: 8px;
          padding: 16px;
          position: relative;
          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-bottom: 1px solid $gray-80;
          }
          box-sizing: border-box;
          .tools-tab {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            display: flex;
            align-items: center;
            text-align: center;
            color: $gray-50;
            height: 32px;
            min-width: 68px;
            padding: 8px;
            cursor: pointer;
            z-index: 1;
            &.active {
              color: #000000;
            }
          }
        }
        .editor-toolbar-tools-wrapper {
          padding: 16px 0;
          height: calc(100% - 42px);
          overflow-y: auto;
          overflow-x: hidden;
          .banjos-input-wrapper {
            &:last-child {
              border-bottom: none;
            }
          }
          .color-input-label {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 48px;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: #000000;
            padding: 0 16px;
          }
          //sectioneditor
          .section-editor-wrapper {
            .section-editor-sections {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding: 12px 16px;
              height: 52px;
              background: #ffffff;
              cursor: pointer;
              span {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
                color: #000000;
                &.hidden {
                  color: $gray-50;
                }
              }
            }
          }
        }
      }
    }
  }
}

.ck-dropdown__panel_sw {
  width: 450px !important;
}