.homepage-wrapper {
  background: #e8e6e6;
  min-height: calc(100vh - 76px);
  .homepage-header-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 74px;
    padding: 16px 32px;
    background-color: #ffffff;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-transform: capitalize;
    color: #000000;
    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      height: 42px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
    .tab {
      padding: 22px 12px;
      border-bottom: 2px solid transparent;
      span {
        font-weight: 600;
        font-size: 22px;
        line-height: 25px;
        text-transform: capitalize;
        color: #000000;
      }
      &.active {
        border-color: $base-color;
      }
    }
  }
  .table-pagelist {
    background-color: #ffffff;
    margin: 44px 34px 0;
    border-radius: 8px 8px 0px 0px;
    padding: 24px 0px;
    .search-and-filter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;
      height: 66px;
      .search-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        padding: 8px 12px;
        width: 451px;
        height: 50px;
        background: $gray-95;
        border-radius: 8px;
        img {
          width: 24px;
          height: 24px;
        }
        input {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: $gray-40;
          background-color: transparent;
          outline: none;
          border: none;
        }
      }
      .banjos-select {
        color: $base-color;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        height: 46px;
      }
      .primary-btn {
        flex-direction: row;
        gap: 16px;
        height: 48px;
      }
    }
    .save-badge {
      background-color: $add-button-color;
      color: $color-primary-dark;
      border-radius: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 106px;
      height: 21px;
      div {
        padding: 3px, 8px, 3px, 8px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
      }
    }
    .publish-badge {
      background-color: $flour-color;
      color: $color-primary-dark;
      border-radius: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 106px;
      height: 21px;

      div {
        padding: 3px, 8px, 3px, 8px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
      }
    }
    .save-btn {
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;
      margin-bottom: 20px;
      &:disabled {
        background-color: grey;
      }
    }
  }
  .empty-pages {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 8px;
    width: 200px;
    margin: calc((100vh - 372px) / 2) auto;
    img {
      width: 200px;
      height: 200px;
    }
    span {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      color: $gray-60;
    }
  }
  .footer-tool {
    .add-category {
      background-color: #5e736026;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      height: 48px;
    }
  }
}
