.template-preview-canvas {
  flex: 1 0;
  overflow: hidden;
  height: 100%;
  background-color: #f7f7f7;
  .template-preview-canvas-wrap {
    height: 100%;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    margin: 0 auto;
    font-size: large;
  }
  &.mobile {
    height: 100vh;
    padding: 16px 30px 16px 30px;
    .template-preview-canvas-wrap {
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: $gray-95; /* color of the tracking area */
        border-width: 0.5px;
        border-style: solid;
        border-color: #cccccc;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-80; /* color of the scroll thumb */
        border-radius: 30px;
        border: 2px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
      }
      width: 400px;
    }
  }
}

.deviceToggle {
  position: fixed;
  bottom: 35px;
  right: 35px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  .selectedDevice {
    height: 55px;
    width: 55px;
    background-color: rgba(255, 255, 255);
    border-radius: 50%;
    display: flex;
    color: #fff;
    text-align: center;
    position: relative;
    z-index: 1;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0 3px 5px 1px rgb(51 51 51 / 30%);
    img {
      width: 30px;
      height: 30px;
    }
  }
  .dropdownDevice {
    position: absolute;
    bottom: 120%;
    text-align: center;
    display: block;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    text-decoration: none;
    margin: 10px auto 0;
    line-height: 1.15;
    color: #fff;
    box-shadow: 0 0 5px 1px rgb(51 51 51 / 30%);
    background-color: rgba(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
